import { Autocomplete, Button, Grid, Stack, TextField, Typography } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { tofieds } from "functions/Functions";
import { headerTable } from "functions/Static";
import DatePicker from "react-date-picker";
import TableTransaction from "./TableTransaction";

import { BeatLoader } from "react-spinners";

const override = {
     display: "flex",
     justifyContent: 'center',
     alignItems: 'center',
};

const ListTransaction = (props) => {
     const { data, language, page, handleChangePage, handleSearch, state, submitSearch, handleDateStartChange, handleDateEndChange, info_list, data_transaction, accountType_list, onChangeAutocomplete, count_page } = props;
     // const count = Math.ceil(data.length > 0 && data.length / 10);
     // const setPage = page - 1;
     // const dataList = data.slice(setPage * 10, page * 10);

     // const count = Math.ceil(data_transaction.count_transaction > 0 && data_transaction.count_transaction / 50);
     const count = Math.ceil(count_page > 0 && count_page / 50);
     const item = data_transaction;

     return (
          <Grid container rowSpacing={5} justifyContent="center" alignItems="center" mt={5}>
               <Grid item xs={12}>
                    <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000", borderBottom: "solid 2px #4589C6" }}>
                         {language === "th" ? "ธุรกรรม" : "Transaction"}
                    </Typography>
                    <Stack direction="row" alignItems="center" mt={2}>
                         <Typography sx={{ fontSize: 20, fontWeight: 600, color: "#000", mr: 2, display: { xs: "none", md: "block" } }}>
                              {language === "th" ? "ค้นหา" : "Search"}:
                         </Typography>
                         <Grid container xs={12} md={12} lg={10} sx={{ mr: 2 }} spacing={1}>
                              <Grid item xs={12} sm={12} md={2.5}>
                                   <Autocomplete
                                        id="search_account"
                                        disableClearable={true}
                                        disablePortal
                                        getOptionLabel={(option) =>
                                             typeof option === 'string' ? option : `${option.label}`
                                        }
                                        options={accountType_list}
                                        value={state.accountType_select}
                                        onChange={(event, value) => onChangeAutocomplete('search_account', value)}
                                        renderInput={(params) => <TextField {...params} label="Select account type" />}
                                   />
                              </Grid>
                              <Grid item xs={12} sm={12} md={2.5}>
                                   <Autocomplete
                                        id="search_mt5"
                                        disableClearable={true}
                                        disablePortal
                                        getOptionLabel={(option) =>
                                             typeof option === 'string' ? option : `${option.label}`
                                        }
                                        options={info_list}
                                        value={state.mt5_select}
                                        onChange={(event, value) => onChangeAutocomplete('search_mt5', value)}
                                        renderInput={(params) => <TextField {...params} label="Select mt5 account" />}
                                   />
                              </Grid>
                              <Grid item xs={12} md={2.5}>
                                   <DatePicker className={"date-picker"} onChange={handleDateStartChange} value={state.date_start} format="dd/MM/yyyy" />
                              </Grid>
                              <Grid item xs={12} md={2.5}>
                                   <DatePicker className={"date-picker"} onChange={handleDateEndChange} value={state.date_end} format="dd/MM/yyyy" />
                              </Grid>
                              <Grid item xs={12} md={2}>
                                   <Button
                                        variant="contained"
                                        style={{ backgroundColor: "#4589C6" }}
                                        //  className="btn-style"
                                        sx={{ maxWidth: 120, fontSize: 16, width: "100%", borderRadius: 40, height: 40, display: { xs: "none", md: "block" } }}
                                        onClick={submitSearch}
                                        onKeyDown={(e) => {
                                             if (e.key === "Enter") {
                                                  submitSearch();
                                             }
                                        }}
                                   >
                                        {language === "th" ? "ค้นหา" : "Search"}
                                   </Button>
                              </Grid>
                         </Grid>
                         <Button
                              variant="contained"
                              color="error"
                              //  className="btn-style"
                              sx={{ maxWidth: 50, fontSize: 16, width: "100%", borderRadius: 40, height: 40, display: { xs: "block", md: "none" } }}
                              onClick={submitSearch}
                         >
                              <SearchIcon />
                         </Button>
                    </Stack>
               </Grid>
               <Grid container spacing={2} my={2}>
                    {head_.map((field, index) =>
                         <Grid key={index} item xs={12} sm={6} md={3}>
                              <Stack direction="row" spacing={1} width="100%" display="flex" justifyContent="flex-strat" alignItems="center">
                                   <Typography>
                                        {field.label}
                                   </Typography>
                                   <Typography sx={{ fontSize: 20, color: "#0d47a1" }}>
                                        {field.suffix}
                                   </Typography>
                                   {item[field.name] !== undefined ?
                                        <Typography sx={{ fontSize: 20, color: "#0d47a1" }}>
                                             {tofieds(item[field.name]) || "0.00"}
                                        </Typography>
                                        :
                                        <BeatLoader
                                             color="#0d47a1"
                                             loading={item[field.name] !== undefined ? false : true}
                                             cssOverride={override}
                                             size={5}
                                             aria-label="Loading Spinner"
                                             data-testid="loader"
                                        />
                                   }
                              </Stack>
                         </Grid>
                    )}
                    {/* <Grid item xs={6} md={3} lg={2}>
                         <Stack spacing={2} direction="row">
                              <Typography variant="body1">Sum Lot : </Typography>
                              <Typography variant="body1" sx={{ color: "#184B8E" }}>
                                   {tofieds(data_transaction?.sumLot) || "0.00"}
                              </Typography>
                         </Stack>
                    </Grid>
                    <Grid item xs={6} md={3} lg={2}>
                         <Stack spacing={2} direction="row">
                              <Typography variant="body1">Profit : </Typography>
                              <Typography variant="body1" sx={{ color: "#184B8E" }}>
                                   ${tofieds(data_transaction?.profit) || "0.00"}
                              </Typography>
                         </Stack>
                    </Grid>
                    <Grid item xs={6} md={3} lg={2}>
                         <Stack spacing={2} direction="row">
                              <Typography variant="body1">Lost :</Typography>
                              <Typography variant="body1" sx={{ color: "#184B8E" }}>
                                   ${tofieds(data_transaction?.lost) || "0.00"}
                              </Typography>
                         </Stack>
                    </Grid>
                    <Grid item xs={6} md={3} lg={2}>
                         <Stack spacing={2} direction="row">
                              <Typography variant="body1">Net Profit : </Typography>
                              <Typography variant="body1" sx={{ color: "#184B8E" }}>
                                   ${tofieds(data_transaction?.net_profit) || "0.00"}
                              </Typography>
                         </Stack>
                    </Grid>
                    <Grid item xs={6} md={3} lg={2}>
                         <Stack spacing={2} direction="row">
                              <Typography variant="body1">Deposit : </Typography>
                              <Typography variant="body1" sx={{ color: "#184B8E" }}>
                                   ${tofieds(data_transaction?.deposit) || "0.00"}
                              </Typography>
                         </Stack>
                    </Grid>
                    <Grid item xs={6} md={3} lg={2}>
                         <Stack spacing={2} direction="row">
                              <Typography variant="body1">Withdraw : </Typography>
                              <Typography variant="body1" sx={{ color: "#184B8E" }}>
                                   ${tofieds(data_transaction?.withdraw) || "0.00"}
                              </Typography>
                         </Stack>
                    </Grid> */}
               </Grid>
               <Grid item xs={12}>
                    <TableTransaction head={headerTable.report_transaction} body={data} count={count} page={page} handleChangePage={handleChangePage} language={language} />
               </Grid>
          </Grid>
     );
};

const head_ = [
     { label: "Lot รวม :", suffix: "$", name: "sumLot" },
     { label: "Profits :", suffix: "$", name: "profit" },
     { label: "Lost :", suffix: "$", name: "lost" },
     { label: "Net Profit :", suffix: "$", name: "net_profit" },
     { label: "Deposit :", suffix: "$", name: "deposit" },
     { label: "Withdraw :", suffix: "$", name: "withdraw" },
]

export default ListTransaction;
